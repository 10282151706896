
import { defineComponent, reactive } from "vue";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";

export default defineComponent({
  setup() {
    const dataCP = reactive({
      data: [] as any,
      valueFilterPeringkat: "skd",
      search: "",
      page: 1,
      pageSize: 10,
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
      } as any,
      listFilterPeringkat: [],
    });

    const titleTable: Array<string> = [
      "Kode",
      "Nama Peserta",
      "Jenis",
      "Total Skor",
      "Status",
      "Tanggal",
    ];

    const getData = async () => {
      cash("#loadingCP").show();

      const hide = () => cash("#loadingCP").hide();
      const page = dataCP.page;
      const pageSize = dataCP.pageSize;
      const search = dataCP.search;

      const url = await api.getConfigFeatureMember('memberPeringkatCat', 'featurePeringkatCat') +
        dataCP.valueFilterPeringkat +
        "?search=" +
        search +
        "&page=" +
        page +
        "&pageSize=" +
        pageSize;
      const hitApi = await api.hitApiGet(url, hide);
      const ds = hitApi.data.diagnostic;
      const rs = hitApi.data.response;

      dataCP.data = rs;
      dataCP.paginate = gpf.paginate(ds.totalData, page, pageSize, 3);

    };

    const getFilterCP = async () => {
      gpf.gLoading.show();

      const rs = await api.getJenisTryout();

      dataCP.listFilterPeringkat = rs;
      dataCP.valueFilterPeringkat = rs[0].kode ? rs[0].kode : "skd";

      await getData();
      gpf.gLoading.hide();
    };
    getFilterCP();

    const changePS = () => {
      getData();
    };

    const goToPage = (p: number) => {
      dataCP.page = p;
      getData();
    };

    const nextPage = () => {
      dataCP.page = dataCP.page + 1;
      getData();
    };

    const beforePage = () => {
      dataCP.page = dataCP.page - 1;
      getData();
    };

    const lastPage = () => {
      dataCP.page = dataCP.paginate.totalPages;
      getData();
    };

    return {
      titleTable,
      dataCP,
      getData,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
    };
  },
  data() {
    return {};
  },
  methods: {
    changeTS(v: any, f: any) {
      const val = v.detail.args[1].key;
      const ex: any = this.dataCP;
      ex[f] = val;
    },
  },
});
